import { AppBar, FilledInput, Hidden, InputAdornment, makeStyles, Toolbar } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import FavoriteIcon from "@material-ui/icons/Favorite"
import MenuIcon from "@material-ui/icons/Menu"
import SearchIcon from "@material-ui/icons/Search"
import { graphql, useStaticQuery } from "gatsby"
import { IconButton } from "gatsby-theme-material-ui"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import BuybagsLogo from "../assets/buybags-logo.svg"
import { RootState } from "../redux/ducks"
import { openFavoritesDrawer, openMenuDrawer } from "../redux/ducks/drawer"
import { setLoading, setQuery } from "../redux/ducks/search"
import { Link } from "./Link"

export const appBarHeight = {
  xs: 102,
  sm: 130,
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: "1px solid lightgray",
    height: appBarHeight.xs,
    [theme.breakpoints.up("sm")]: {
      height: appBarHeight.sm,
    },
  },
  appBarNav: {
    "& ul": {
      whiteSpace: "nowrap",
      listStyle: "none",
      margin: 0,
      paddingLeft: 0,
      paddingTop: theme.spacing(1),
      textAlign: "center",
      width: "100%",

      "& li": {
        display: "inline-block",
        "&:not(:last-child)": {
          paddingRight: 12,
        },
      },
    },
  },
  buttons: {
    display: "flex",
    width: "100%",
  },
  categoryLink: {
    "&:nth-child(n+3)": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  favoriteIcon: {
    color: theme.palette.error.main,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    width: "100%",
  },
  inputRoot: {
    borderRadius: 8,
    color: "inherit",
  },
  logo: {
    flexGrow: 1,
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  offset: {
    ...theme.mixins.toolbar,
    height: `${appBarHeight.xs}px`,
    minHeight: `${appBarHeight.xs}px`,
    [theme.breakpoints.up("sm")]: {
      height: `${appBarHeight.sm}px`,
      minHeight: `${appBarHeight.sm}px`,
    },
  },
  sale: {
    color: theme.palette.error.main,
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    maxWidth: `calc(${theme.breakpoints.values.lg}px - ${theme.spacing(8)}px)`,
    position: "relative",
    width: "100%",
  },
  searchIcon: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    pointerEvents: "none",
    position: "absolute",
    width: theme.spacing(7),
  },
  toolbar: {
    flexDirection: "column",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}))

export const Header: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const searchQuery = useSelector((state: RootState) => state.search.query)

  function handleOpenFavoritesDrawer() {
    dispatch(openFavoritesDrawer())
  }

  function handleOpenMenuDrawer() {
    dispatch(openMenuDrawer())
  }

  function setSearchLoading(loading: boolean) {
    dispatch(setLoading(loading))
  }

  function setSearchQuery(query: string) {
    dispatch(setQuery(query))
  }

  const data = useStaticQuery(staticQuery)

  const handleClearSearch = (): void => {
    setSearchQuery(``)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setSearchLoading(true)
    const value = event.target.value
    setSearchQuery(value)
  }

  return (
    <>
      <AppBar className={classes.appBar} color="secondary" position="fixed" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.buttons}>
            <IconButton
              aria-label="Menü öffnen"
              className={classes.menuButton}
              color="inherit"
              edge="start"
              onClick={handleOpenMenuDrawer}
            >
              <MenuIcon />
            </IconButton>

            <div className={classes.logo}>
              <Link aria-label="Buybags Logo" href="/" underline="none">
                <BuybagsLogo alt="Das Logo von buybags.de" height={40} width={120} />
              </Link>
            </div>

            <IconButton
              aria-label="Wunschliste öffnen"
              className={classes.favoriteIcon}
              edge="end"
              onClick={handleOpenFavoritesDrawer}
            >
              <FavoriteIcon />
            </IconButton>
          </div>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <FilledInput
              classes={{
                input: classes.inputInput,
                root: classes.inputRoot,
              }}
              disableUnderline={true}
              fullWidth={true}
              inputProps={{ "aria-label": "Suche" }}
              onChange={handleInputChange}
              placeholder="Hier Tasche suchen..."
              value={searchQuery}
              endAdornment={
                searchQuery !== `` && (
                  <InputAdornment position="end">
                    <IconButton aria-label="Suchfeld leeren" onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </div>

          <Hidden only="xs" implementation="css">
            <nav className={classes.appBarNav}>
              <ul>
                {data.categoryPages.edges.map(({ node }: any, index: number) => (
                  <li className={classes.categoryLink} key={index}>
                    <Link href={node.path} underline="hover">
                      {node.context.category}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link href={`/luxustaschen/`} underline="hover">
                    Luxustaschen
                  </Link>
                </li>
                <li>
                  <Link href={`/marken/`} underline="hover">
                    Marken
                  </Link>
                </li>
                <li>
                  <Link className={classes.sale} href={`/sale/`} underline="hover">
                    Sale
                  </Link>
                </li>
              </ul>
            </nav>
          </Hidden>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </>
  )
}

const staticQuery = graphql`
  query HeaderQuery {
    categoryPages: allSitePage(
      filter: {
        context: { category: { ne: null }, humanPageNumber: { eq: 1 }, sale: { eq: false } }
      }
      limit: 7
      sort: { fields: [context___numberOfPages], order: DESC }
    ) {
      edges {
        node {
          path
          context {
            category
          }
        }
      }
    }
  }
`
