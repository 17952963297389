import btoa from "abab/lib/btoa"

export type ImageEdits = Record<string, unknown>

export interface ImageRequest {
  bucket: string
  key: string
  edits: ImageEdits
}

export const getImgSrc = (bucket: string, key: string, edits: ImageEdits = {}): string => {
  const request: ImageRequest = { bucket, key, edits }
  const base64EncodedRequest = btoa(JSON.stringify(request))
  return `https://${bucket}/${base64EncodedRequest}`
}
