import { LinkProps as MuiLinkProps } from "@material-ui/core"
import { Link as GatsbyMuiLink } from "gatsby-theme-material-ui"
import React from "react"

// Keep the LinkProps interface like the MuiLinkProps
// so we don't have to refactor all the links in case
// we switch from Gatsby to Next.js.
export type LinkProps = MuiLinkProps

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { href } = props
  const modifiedProps = props as MuiLinkProps & { to?: string }
  if (typeof href !== "undefined") {
    if (href.startsWith(`/`)) {
      // Replace `href` with `to` if it is an internal link.
      modifiedProps.to = href
      delete modifiedProps.href
    } else if (href.startsWith("http")) {
      // By default we want to open external links in a new tab/window,
      // but it should still be possible to change that behavior.
      const { target } = props
      if (typeof target === "undefined" || target === `_blank`) {
        // If target="_blank" we have to add rel="noopener" to
        // avoid the exploitation of the `window.opener` API.
        props.target = `_blank`
        const { rel } = props
        const linkTypes = new Set(rel ? rel.split(/\W+/) : [])
        linkTypes.add(`noopener`)
        props.rel = Array.from(linkTypes).join(" ")
      }
    }
  }
  return <GatsbyMuiLink ref={ref} {...modifiedProps} />
})

Link.displayName = `Link`
