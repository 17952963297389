import { Container, Divider, makeStyles, Typography } from "@material-ui/core"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import PinterestIcon from "@material-ui/icons/Pinterest"
import { IconButton } from "gatsby-theme-material-ui"
import React from "react"

import { copyright } from "../utils/copyright"
import { Link } from "./Link"

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minHeight: 200 /* Somehow Safari requires minHeight here. */,
    padding: theme.spacing(2),

    "& ul": {
      listStyle: "none",
      margin: 0,
      padding: 0,

      "& li": {
        display: "inline-block",

        "& a": {
          padding: theme.spacing(1),
        },
      },
    },
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))

export const Footer: React.FC = () => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Divider variant="fullWidth" className={classes.divider} />
      <Container component="footer" className={classes.container} fixed={true}>
        <ul>
          <Typography component="li">
            <IconButton
              aria-label={"buybags.de bei Facebook"}
              color="primary"
              href={"https://www.facebook.com/buybagsDE/"}
              rel="noopener"
              target={"_blank"}
            >
              <FacebookIcon fontSize="large" />
            </IconButton>
          </Typography>
          <Typography component="li">
            <IconButton
              aria-label={"buybags.de bei Instagram"}
              color="primary"
              href={"https://www.instagram.com/buybags.de/"}
              rel="noopener"
              target={"_blank"}
            >
              <InstagramIcon fontSize="large" />
            </IconButton>
          </Typography>
          <Typography component="li">
            <IconButton
              aria-label={"buybags.de bei Pinterest"}
              color="primary"
              href="https://www.pinterest.com/buybagsDE/"
              rel="noopener"
              target={"_blank"}
            >
              <PinterestIcon fontSize="large" />
            </IconButton>
          </Typography>
        </ul>
        <ul>
          <Typography component="li">
            <Link href={`/agb/`}>AGB</Link>
          </Typography>
          <Typography component="li">
            <Link href={`/datenschutz/`}>Datenschutz</Link>
          </Typography>
          <Typography component="li">
            <Link href={`/impressum/`}>Impressum</Link>
          </Typography>
        </ul>
        <Typography component="span">{copyright(2019, `buybags.de`)}</Typography>
        <Typography component="span" variant="body2" align="center">
          Preisangaben inkl. MwSt. und ggf. zzgl. Versandkosten.
        </Typography>
      </Container>
    </React.Fragment>
  )
}
