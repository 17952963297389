import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"
import "lazysizes/plugins/blur-up/ls.blur-up"
import "lazysizes/plugins/object-fit/ls.object-fit"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import React from "react"

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  mediabox: {
    position: "relative",
    display: "block",
    height: 0,
    width: "100%",
    paddingBottom: "100%",
    "& .mediabox-img.ls-blur-up-is-loading, & .mediabox-img.lazyload:not([src])": {
      visibility: "hidden",
    },
    "& .ls-blur-up-img, & .mediabox-img": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "block",
      // only if you want to change the blur-up option from always to
      // auto or want to use blur up effect without a lowsrc image.
      fontFamily: `"blur-up: auto", "object-fit: contain"`,
      objectFit: "contain",
    },
    "& .ls-blur-up-img": {
      filter: "blur(10px)",
      opacity: 1,
      transition: "opacity 300ms, filter 300ms",
    },
    "& .ls-blur-up-img.ls-inview.ls-original-loaded": {
      opacity: 0,
      filter: "blur(5px)",
    },
  },
})

export type LazySizesImgProps = React.ImgHTMLAttributes<HTMLImageElement>

export const LazySizesImg: React.FC<LazySizesImgProps> = (props) => {
  const classes = useStyles()
  const { className, ...otherProps } = props
  return (
    <div className={clsx(className, classes.wrapper)}>
      <div className={classes.mediabox}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img {...otherProps} className={clsx("mediabox-img", "lazyload")} />
      </div>
    </div>
  )
}
