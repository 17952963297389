import { Breadcrumbs as MuiBreadcrumbs, Hidden, makeStyles, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"
import React from "react"
import { JsonLd } from "react-schemaorg"
import { BreadcrumbList, WithContext } from "schema-dts"

import { Link } from "./Link"

const MAX_LABEL_LENGTH = 30

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0`,
  },
}))

interface Crumb {
  crumbLabel: string
  pathname: string
}

export interface BreadcrumbsProps {
  crumbs: Crumb[]
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { crumbs } = props

  if (crumbs.length <= 1) {
    return null // Do not show breadcrumbs on the home page.
  }

  const classes = useStyles(props)

  const data = useStaticQuery(query)
  const { site } = data
  const { siteMetadata } = site
  const { siteUrl } = siteMetadata

  const trailingSlashCrumbs = crumbs.map(({ crumbLabel, pathname }) => ({
    crumbLabel,
    pathname: `${_.trimEnd(pathname, `/`)}/`,
  }))

  const oneLevelUp = trailingSlashCrumbs[trailingSlashCrumbs.length - 2]

  const breadcrumbList: WithContext<BreadcrumbList> = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: trailingSlashCrumbs.map(({ pathname, crumbLabel }, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: crumbLabel,
      item: `${siteUrl}${pathname}`,
    })),
  }

  return (
    <>
      <JsonLd<BreadcrumbList> item={breadcrumbList} />
      <Hidden smDown={true} implementation="css">
        <MuiBreadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbs}>
          {trailingSlashCrumbs.map(({ crumbLabel, pathname }, index) => {
            const label = _.truncate(crumbLabel, { length: MAX_LABEL_LENGTH })
            return index === crumbs.length - 1 ? (
              <Typography color="textPrimary" gutterBottom={false} key={index}>
                {label}
              </Typography>
            ) : (
              <Link href={pathname} key={index} underline="always">
                {label}
              </Link>
            )
          })}
        </MuiBreadcrumbs>
      </Hidden>
      <Hidden mdUp={true} implementation="css">
        <MuiBreadcrumbs separator="‹" aria-label="breadcrumb" className={classes.breadcrumbs}>
          <Hidden xsUp={true} implementation="css">
            <span /> {/* hidden dummy crumb */}
          </Hidden>
          <Link href={oneLevelUp.pathname} underline="always">
            {_.truncate(oneLevelUp.crumbLabel, { length: MAX_LABEL_LENGTH })}
          </Link>
        </MuiBreadcrumbs>
      </Hidden>
    </>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
