import { makeStyles } from "@material-ui/core"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import clsx from "clsx"
import { graphql, useStaticQuery } from "gatsby"
import { Button } from "gatsby-theme-material-ui"
import React, { ReactElement } from "react"

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    borderRadius: 0,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  href: string
  name: string
  productId?: string
  commission?: string
  currency?: string
  size?: "small" | "medium" | "large"
  className?: string
}

export function ShopButton(props: Props): ReactElement {
  const classes = useStyles(props)
  const { href, name, productId, commission = 0, currency = "EUR", size = "large", className } = props

  // const dataProps = productId ? {
  //   "data-currency": currency,
  //   "data-product-id": productId,
  //   "data-product-name": name,
  //   "data-value": commission
  // } : {}
  const dataProps = {}

  return (
    <Button
      aria-label="Zum Shop"
      classes={{
        root: classes.buttonRoot,
      }}
      className={clsx(className, "shop-button")}
      color="primary"
      {...dataProps}
      fullWidth={true}
      href={href}
      rel="noopener sponsored"
      size={size}
      target="_blank"
      variant="contained"
    >
      Zum Shop
      <ArrowForwardIcon className={classes.icon} />
    </Button>
  )
}
