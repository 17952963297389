import { CircularProgress, Grid, GridSize, makeStyles } from "@material-ui/core"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { JsonLd } from "react-schemaorg"
import { ItemList, WithContext } from "schema-dts"

import {
  ExternalSearchResult,
  InternalSearchResult,
  SearchResultData,
} from "../interfaces/searchResult"
import { SearchResult } from "./SearchResult"

const useStyles = makeStyles({
  container: {
    flex: "1 0 auto",
  },
})

export interface SearchResultsProps {
  breakpoints?: Partial<Record<Breakpoint, boolean | GridSize>>
  results: SearchResultData[]
  searchQuery?: string
  loading?: boolean
}

export const SearchResults: React.FC<SearchResultsProps> = (props) => {
  const classes = useStyles()
  const {
    breakpoints = {
      lg: 3 as GridSize,
      md: 4 as GridSize,
      xs: 6 as GridSize,
    },
    results,
    searchQuery = ``,
    loading = false,
  } = props

  const data = useStaticQuery(query)
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = data

  const itemListSchema: WithContext<ItemList> = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: results.map((result, index) => {
      const { href } = result as ExternalSearchResult
      const { slug } = result as InternalSearchResult
      return {
        "@type": "ListItem",
        position: index + 1,
        url: href || `${siteUrl}${slug}`,
      }
    }),
  }

  return (
    <Grid
      container={true}
      spacing={2}
      justify="center"
      alignContent="center"
      className={classes.container}
    >
      {loading ? (
        <Grid item={true}>
          <CircularProgress />
        </Grid>
      ) : results.length > 0 ? (
        <>
          <JsonLd<ItemList> item={itemListSchema} />
          {results.map((result, index) => (
            <SearchResult result={result} key={index} breakpoints={breakpoints} />
          ))}
        </>
      ) : (
        <Grid item={true}>Keine Ergebnisse für »{searchQuery}«</Grid>
      )}
    </Grid>
  )
}

const query = graphql`
  query SearchResultsQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
