import { Box, Container, makeStyles, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"
import React from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"

import { PageContext } from "../interfaces/pageContext"
import { SearchResultData } from "../interfaces/searchResult"
import { RootState } from "../redux/ducks"
import { setLoading, setQuery, setResults } from "../redux/ducks/search"
import { BackToTopButton } from "./BackToTopButton"
import { Breadcrumbs } from "./Breadcrumbs"
import { FavoritesDrawer } from "./FavoritesDrawer"
import { Footer } from "./Footer"
import { GdprModal } from "./GdprModal"
import { Header } from "./Header"
import { MenuDrawer } from "./MenuDrawer"
import { SearchResults } from "./SearchResults"

const UPDATE_DELAY = 500

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    margin: "auto",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  brand: {
    display: "inline",
  },
  searchResults: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: "center",
    flex: "1 0 auto",
  },
}))

export interface LayoutProps {
  children: React.ReactNode
  location: Location
  pageContext: PageContext
  showGdprModal?: boolean
}

export const Layout: React.FC<LayoutProps> = (props) => {
  const { children, location, pageContext, showGdprModal = true } = props
  const { breadcrumb, title } = pageContext

  const dispatch = useDispatch()
  const searchLoading = useSelector((state: RootState) => state.search.loading)
  const searchQuery = useSelector((state: RootState) => state.search.query)
  const searchResults = useSelector((state: RootState) => state.search.results)

  function setSearchLoading(loading: boolean) {
    dispatch(setLoading(loading))
  }

  function setSearchQuery(query: string) {
    dispatch(setQuery(query))
  }

  function setSearchResults(results: SearchResultData[]) {
    dispatch(setResults(results))
  }

  const classes = useStyles()

  const { site } = useStaticQuery(staticQuery)
  const { siteMetadata } = site
  const { itemsPerPage, searchEndpoint, siteUrl } = siteMetadata

  const url = `${siteUrl}${breadcrumb ? breadcrumb.location : `/404`}`

  /* Do not remove the useRef or you will get strange console warnings. */
  const updateResults = React.useRef(
    _.debounce(
      (query: string) => {
        fetch(`${searchEndpoint}?query=${query}&limit=${itemsPerPage}`)
          .then((response) => response.json())
          .then(({ result }) => {
            setSearchResults(result)
            setSearchLoading(false)
          })
      },
      UPDATE_DELAY,
      { trailing: true }
    )
  )

  React.useEffect(() => {
    setSearchQuery(new URLSearchParams(location.search).get(`q`) || ``)
    if (searchQuery) {
      setSearchLoading(true)
      updateResults.current(searchQuery)
    }
  }, [location.search])

  return (
    <React.StrictMode>
      <div className={classes.root}>
        <Helmet
          defer={false}
          htmlAttributes={{ lang: site.siteMetadata.lang }}
          titleTemplate={`%s | ${site.siteMetadata.title}`}
        >
          <title>{title}</title>
          <meta name="description" content={site.siteMetadata.description} />
          <meta property="fb:app_id" content={site.siteMetadata.facebook.appId} />
          <meta property="og:description" content={site.siteMetadata.facebook.description} />
          <meta property="og:image" content={site.siteMetadata.facebook.image.url} />
          <meta property="og:image:alt" content={site.siteMetadata.facebook.image.alt} />
          <meta property="og:image:width" content={site.siteMetadata.facebook.image.width} />
          <meta property="og:image:height" content={site.siteMetadata.facebook.image.height} />
          <meta property="og:locale" content={site.siteMetadata.facebook.locale} />
          <meta property="og:site_name" content={site.siteMetadata.facebook.siteName} />
          <meta property="og:title" content={site.siteMetadata.facebook.title} />
          <meta property="og:type" content={`website`} />
          <meta property="og:url" content={url} />
          <meta name="p:domain_verify" content={site.siteMetadata.pinterest.domainVerify} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={site.siteMetadata.twitter.site} />
        </Helmet>

        <Header />

        <MenuDrawer />

        <FavoritesDrawer />

        <Container component="main" className={classes.main} maxWidth="lg">
          {searchQuery ? (
            <>
              <Breadcrumbs
                crumbs={breadcrumb.crumbs.concat([
                  {
                    pathname: location.pathname,
                    crumbLabel: "Suche",
                  },
                ])}
              />
              <Box display="flex" flexDirection="column" flex="1 0 auto" textAlign="center">
                <Typography variant="h1" className={classes.brand}>
                  Suchergebnisse für »{searchQuery}«
                </Typography>
                <SearchResults
                  searchQuery={searchQuery}
                  results={searchResults}
                  loading={searchLoading}
                />
              </Box>
            </>
          ) : (
            <>
              {breadcrumb && breadcrumb.crumbs && <Breadcrumbs crumbs={breadcrumb.crumbs} />}
              {children}
            </>
          )}
        </Container>

        <Footer />

        <BackToTopButton />

        {showGdprModal && <GdprModal />}
      </div>
    </React.StrictMode>
  )
}

const staticQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        description
        facebook {
          appId
          description
          image {
            url
            alt
            width
            height
          }
          locale
          siteName
          title
        }
        itemsPerPage
        lang
        pinterest {
          domainVerify
        }
        searchEndpoint
        siteUrl
        title
        twitter {
          site
        }
      }
    }
  }
`
