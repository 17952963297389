import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { IconButton } from "gatsby-theme-material-ui"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "../redux/ducks"
import { closeDrawer } from "../redux/ducks/drawer"

const DRAWER_WIDTH = 304

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    alignItems: "center",
    display: "flex",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerTitle: {
    flexGrow: 1,
  },
}))

export const MenuDrawer: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const open = useSelector((state: RootState) => state.drawer.openDrawer === "MenuDrawer")

  function handleCloseDrawer() {
    dispatch(closeDrawer())
  }

  const data = useStaticQuery(query)

  const menuContent = [
    {
      heading: "Allgemein",
      items: data.site.siteMetadata.menuLinks,
    },
    {
      heading: "Taschen-Kategorien",
      items: data.allSitePage.edges.map(({ node }: any) => ({
        name: node.context.category,
        slug: node.path,
      })),
    },
  ]

  return (
    <Drawer
      className={classes.drawer}
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleCloseDrawer}
    >
      <div className={classes.drawerHeader}>
        <Typography align="center" component="span" variant="h4" className={classes.drawerTitle}>
          Menü
        </Typography>
        <IconButton aria-label="Menü schließen" onClick={handleCloseDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      {menuContent.map((itemGroup, itemGroupIndex) => (
        <React.Fragment key={itemGroupIndex}>
          <Divider />
          <List
            subheader={
              <ListSubheader component="div" disableSticky={true}>
                {itemGroup.heading}
              </ListSubheader>
            }
          >
            {itemGroup.items.map((item: any, itemIndex: number) => {
              const handleClick = (): void => {
                navigate(item.slug)
                handleCloseDrawer()
              }
              return (
                <ListItem button={true} key={itemIndex} onClick={handleClick}>
                  <ListItemText>{item.name}</ListItemText>
                </ListItem>
              )
            })}
          </List>
        </React.Fragment>
      ))}
    </Drawer>
  )
}

const query = graphql`
  query MenuDrawerQuery {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          slug
        }
      }
    }
    allSitePage(
      filter: {
        context: { category: { ne: null }, humanPageNumber: { eq: 1 }, sale: { eq: false } }
      }
      sort: { fields: [context___category], order: ASC }
    ) {
      edges {
        node {
          path
          context {
            category
          }
        }
      }
    }
  }
`
