import { makeStyles, useTheme, Zoom } from "@material-ui/core"
import UpIcon from "@material-ui/icons/KeyboardArrowUp"
import { Fab } from "gatsby-theme-material-ui"
import React from "react"
import Scroll from "react-scroll"
import { useScrollData } from "scroll-data-hook"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

export interface BackToTopButtonProps {
  visibleAt?: number
}

export const BackToTopButton: React.FC<BackToTopButtonProps> = (props) => {
  const { visibleAt = 150 } = props
  const theme = useTheme()
  const classes = useStyles(props)
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  }
  const scroll = Scroll.animateScroll

  const handleClick = () => {
    scroll.scrollToTop()
  }
  const { position } = useScrollData()
  const visible = position.y > visibleAt
  return (
    <Zoom
      in={visible}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${visible ? transitionDuration.exit : 0}ms`,
      }}
      unmountOnExit={true}
    >
      <Fab
        aria-label="Zurück nach oben"
        className={classes.fab}
        color={`secondary`}
        onClick={handleClick}
      >
        <UpIcon />
      </Fab>
    </Zoom>
  )
}
