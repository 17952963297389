import {
  Avatar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import DeleteIcon from "@material-ui/icons/Delete"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { IconButton } from "gatsby-theme-material-ui"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { Favorite } from "../interfaces/favorite"
import { RootState } from "../redux/ducks"
import { closeDrawer } from "../redux/ducks/drawer"
import { removeFavorite } from "../redux/ducks/favorites"
import { getImgSrc } from "../utils/images"

const AVATAR_HEIGHT = 40
const DRAWER_WIDTH = 304

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.background.default,
    objectFit: "contain",
  },
  drawer: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    alignItems: "center",
    display: "flex",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerTitle: {
    flexGrow: 1,
  },
}))

export const FavoritesDrawer: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const favorites = useSelector((state: RootState) => state.favorites.favorites)
  const open = useSelector((state: RootState) => state.drawer.openDrawer === "FavoritesDrawer")

  const data = useStaticQuery(query)
  const { site } = data
  const { siteMetadata } = site
  const { imageBucket } = siteMetadata

  function handleCloseDrawer() {
    dispatch(closeDrawer())
  }

  function handleRemoveFavorite(favorite: Favorite) {
    dispatch(removeFavorite(favorite))
  }

  return (
    <Drawer
      anchor="right"
      className={classes.drawer}
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleCloseDrawer}
    >
      <div className={classes.drawerHeader}>
        <IconButton aria-label="Wunschliste schließen" onClick={handleCloseDrawer}>
          <ChevronRightIcon />
        </IconButton>
        <Typography align="center" component="span" variant="h4" className={classes.drawerTitle}>
          Wunschliste
        </Typography>
      </div>
      <Divider />
      <List>
        {favorites.length > 0 ? (
          favorites.map((favorite, key) => {
            function handleClick() {
              navigate(favorite.slug)
              handleCloseDrawer()
            }
            function handleDelete() {
              handleRemoveFavorite(favorite)
            }
            const src1x = getImgSrc(imageBucket, favorite.image, {
              resize: {
                width: AVATAR_HEIGHT,
                height: AVATAR_HEIGHT,
                fit: "contain",
                background: "white",
              },
            })
            const src2x = getImgSrc(imageBucket, favorite.image, {
              resize: {
                width: 2 * AVATAR_HEIGHT,
                height: 2 * AVATAR_HEIGHT,
                fit: "contain",
                background: "white",
              },
            })
            const src = src2x
            const srcSet = `${src1x}, ${src2x} 2x`
            return (
              <React.Fragment key={key}>
                <ListItem button={true} onClick={handleClick}>
                  <ListItemAvatar>
                    <Avatar
                      alt={favorite.name}
                      classes={{ img: classes.avatar }}
                      src={src}
                      srcSet={srcSet}
                      variant="square"
                    />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography variant="body2">{favorite.name}</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="Tasche aus der Wunschliste entfernen."
                      onClick={handleDelete}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            )
          })
        ) : (
          <ListItem button={false}>
            <ListItemText>
              <Typography align="center">Wunschliste ist leer</Typography>
            </ListItemText>
          </ListItem>
        )}
      </List>
    </Drawer>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        imageBucket
      }
    }
  }
`
