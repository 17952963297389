import { Box, Grid, GridSize, makeStyles, Typography } from "@material-ui/core"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import clsx from "clsx"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

import {
  ExternalSearchResult,
  InternalSearchResult,
  SearchResultData,
} from "../interfaces/searchResult"
import { formatPrice } from "../utils/format"
import { getImgSrc } from "../utils/images"
import { LazySizesImg } from "./LazySizesImg"
import { Link } from "./Link"
import { ShopButton } from "./ShopButton"

const useStyles = makeStyles((theme) => ({
  productItem: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  productLink: {
    position: "absolute",
    width: `calc(100% - ${theme.spacing(2)}px)`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
    margin: 0,
    zIndex: 1,
  },
  image: {
    maxHeight: 150,
    maxWidth: 150,
    [theme.breakpoints.up("sm")]: {
      maxHeight: 225,
      maxWidth: 225,
    },
  },
  hidden: {
    clip: "rect(1px,1px,1px,1px)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    top: "auto",
    width: 1,
  },
  button: {
    paddingTop: theme.spacing(1),
    width: "90%",
    margin: "auto",
  },
  productName: {
    width: "90%",
    fontSize: "1rem",
    margin: "10px auto 10px auto",
    display: "-webkit-box",
    overflow: "hidden",
    lineClamp: 3,
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    paddingTop: `calc(150px + 10px)`,
    [theme.breakpoints.up("sm")]: {
      paddingTop: `calc(225px + 10px)`,
    },
  },
  price: {
    display: "inline-block",
    fontSize: "1.1rem",
    paddingLeft: 4,
    paddingTop: `calc(3 * 1.17rem + 2 * 10px)`,
  },
  priceBefore: {
    color: theme.palette.text.primary,
    display: "inline-block",
    fontSize: "1.1rem",
    fontWeight: "bold",
    textDecoration: "line-through",
    paddingRight: theme.spacing(1),
  },
  sale: {
    color: theme.palette.error.main,
  },
  product: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))

export interface SearchResultProps {
  result: SearchResultData
  breakpoints?: Partial<Record<Breakpoint, boolean | GridSize>>
  imageHeight?: number
  imageWidth?: number
}

export const SearchResult: React.FC<SearchResultProps> = (props) => {
  const classes = useStyles(props)
  const { result, breakpoints, imageHeight = 150, imageWidth = 150 } = props
  const { currency = "EUR", images, name, price } = result
  const { deepLink, priceBefore, sale, slug } = result as InternalSearchResult
  const { href = deepLink } = result as ExternalSearchResult

  const [image] = useState(images[0])

  const data = useStaticQuery(query)
  const { site } = data
  const { siteMetadata } = site
  const { imageBucket } = siteMetadata

  const imgProps: Record<string, unknown> = { "data-sizes": "auto" }
  if (image.src.startsWith("http")) {
    imgProps["data-src"] = image.src
  } else {
    imgProps["data-srcset"] = [1, 2, 3, 4]
      .map((size) => {
        const width = size * imageWidth
        const height = size * imageHeight
        const src = getImgSrc(imageBucket, image.src, {
          resize: {
            width,
            height,
            fit: "contain",
            background: "white",
          },
        })
        return `${src} ${width}w`
      })
      .join(", ")
    imgProps["data-lowsrc"] = getImgSrc(imageBucket, image.src, {
      resize: {
        width: imageWidth / 2,
        height: imageHeight / 2,
        fit: "contain",
        background: "white",
      },
    })
  }

  return (
    <Grid item={true} {...breakpoints} component="article" className={classes.productItem}>
      <Link className={classes.productLink} href={slug ? slug : undefined} underline="none">
        <Typography variant="h2" align="center" className={classes.productName}>
          {name}
        </Typography>
      </Link>
      <LazySizesImg
        alt={image.alt}
        className={classes.image}
        draggable={false}
        title={image.title}
        {...imgProps}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        py={1}
        textAlign="center"
        width="90%"
        margin="auto"
        flexGrow={1}
      >
        {/* NOTE: Nesting a span within a span to lower the number of DOM elements. */}
        <span className={clsx(classes.price, sale && classes.sale)}>
          {sale && (
            <span className={classes.priceBefore}>{formatPrice(priceBefore, currency)}</span>
          )}
          {formatPrice(price, currency)}
        </span>
      </Box>
      {!slug && <ShopButton href={href} name={name} size="small" className={classes.button} />}
    </Grid>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        imageBucket
      }
    }
  }
`
