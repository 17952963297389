import { createStyles, makeStyles, Modal, Typography } from "@material-ui/core"
import { Button } from "gatsby-theme-material-ui"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "../redux/ducks"
import { giveConsent } from "../redux/ducks/gdpr"
import { Link } from "./Link"

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      padding: theme.spacing(1),
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      maxWidth: 600,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1),
      borderRadius: 4,
      outline: 0,
    },
    content: {
      padding: theme.spacing(2),
    },
  })
)

export const GdprModal: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const open = useSelector((state: RootState) => !state.gdpr.consent)

  function handleClose() {
    dispatch(giveConsent())
  }

  return (
    <Modal open={open} aria-describedby="gdpr-modal-description" className={classes.modal}>
      <div className={classes.paper}>
        <Typography id="gdpr-modal-description" className={classes.content}>
          Wir nutzen Cookies, um eine Vielzahl von Services anzubieten, diese stetig zu verbessern
          sowie Werbung entsprechend Deinen Interessen auf unserer Website, Social Media und
          Partnerwebsites anzuzeigen. Mit Klick auf „OK“ willigst Du in die Verwendung von Cookies
          ein. Du kannst deine Cookie-Einstellungen jederzeit ändern bzw. widerrufen. Mehr erfährst
          Du in der{" "}
          <Link href="/datenschutz/" underline="always">
            Datenschutzerklärung
          </Link>
          .
        </Typography>
        <Button
          color="primary"
          fullWidth={true}
          key="close"
          onClick={handleClose}
          size="large"
          variant="contained"
        >
          OK
        </Button>
      </div>
    </Modal>
  )
}
