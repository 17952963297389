export function formatPrice(value: number, currency: string): string {
  const fractionDigits = Number.isInteger(value) ? 0 : 2
  const options = {
    currency,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    style: "currency",
    useGrouping: false,
  }
  const numberFormat = new Intl.NumberFormat("de-DE", options)
  return numberFormat.format(value).replace(" ", "&nbsp;")
}

export function formatPercent(value: number, fractionDigits = 0): string {
  const options = {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    style: "percent",
    useGrouping: false,
  }
  const numberFormat = new Intl.NumberFormat("de-DE", options)
  return numberFormat.format(value).replace(" ", "&nbsp;")
}
