export function copyright(copyrightYear: number, rightsHolder: string): string {
  const components = [`Copyright`, `©`, `${copyrightYear}`]
  const currentYear = new Date().getFullYear()
  if (currentYear > copyrightYear) {
    components.push(`-`)
    components.push(`${currentYear}`)
  }
  components.push(rightsHolder)
  return components.join(` `)
}
